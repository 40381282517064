//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Swiper, Navigation, Pagination } from 'swiper'
import hasWindow from '~/mixins/hasWindow'

import IconArrowLeftCircle from '~/assets/svg/arrow-left-circle.svg?inline'
import IconArrowRightCircle from '~/assets/svg/arrow-right-circle.svg?inline'

Swiper.use([Navigation, Pagination])

export default {
  components: { IconArrowLeftCircle, IconArrowRightCircle },

  mixins: [hasWindow],

  props: { gallery: { type: Object, default: () => ({}) } },

  mounted() {
    this.initSwiper()
  },

  beforeDestroy() {
    this.swiper && this.swiper.destroy()
  },

  methods: {
    initSwiper() {
      this.swiper = new Swiper(this.$el.querySelector('.swiper-container'), {
        navigation: {
          nextEl: this.$el.querySelector('.swiper-button-next'),
          prevEl: this.$el.querySelector('.swiper-button-prev'),
        },
        pagination: {
          el: this.$el.querySelector('.swiper-pagination'),
        },
        resistanceRatio: 0,
      })
    },

    handleOpenModal() {
      this.$refs.galleryItemImageModal.show()
      this.$refs.galleryItemImageModal.initSwiper()
    },
  },
}
