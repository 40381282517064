function insertScript() {
  const script = document.createElement('script')
  script.src = 'https://www.youtube.com/iframe_api'
  document.head.appendChild(script)

  window.onYouTubeIframeAPIReady = () =>
    document.dispatchEvent(new CustomEvent('youTubeIframeAPIReady'))
}

export default {
  methods: {
    async youTubeIframeAPIReady() {
      if (window.YT && window.YT.loaded) return

      await new Promise((resolve) =>
        document.addEventListener('youTubeIframeAPIReady', resolve)
      )
    },
  },

  mounted() {
    const script = document.head.querySelector(
      'script[src="https://www.youtube.com/iframe_api"]'
    )
    script || insertScript()
  },
}
