export default {
  methods: {
    openShareWindow(URL) {
      const width = 540
      const height = 720
      const top = (window.innerHeight - height) / 2
      const left = (window.innerWidth - width) / 2
      const windowFeatures = `width=${width}, height=${height}, top=${top}, left=${left}`

      window.open(URL, null, windowFeatures)
    },

    shareFacebook(URL) {
      URL = encodeURIComponent(URL)
      const shareURL = `https://www.facebook.com/sharer.php?u=${URL}`

      this.openShareWindow(shareURL)
    },

    shareWhatsApp(URL, text = '') {
      URL = encodeURIComponent(URL)
      const shareURL = `https://api.whatsapp.com/send?text=${text}%20${URL}`

      this.openShareWindow(shareURL)
    },

    shareTwitter(URL) {
      URL = encodeURIComponent(URL)
      const shareURL = `https://twitter.com/share?url=${URL}`
      this.openShareWindow(shareURL)
    },

    canShareCopy() {
      return Boolean(navigator.clipboard)
    },

    async shareCopy(URL, text) {
      await navigator.clipboard.writeText(`${text} ${URL}`)
      this.$alert.alert('info', 'Text telah dicopy.')
    },
  },
}
