//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'
import BootstrapModal from '../bootstrap/BootstrapModal'
import canShare from '~/mixins/canShare'
import hasWindow from '~/mixins/hasWindow'

import IconCloseCircle from '~/assets/svg/close-circle.svg?inline'
import IconCopy from '~/assets/svg/copy.svg?inline'
import IconFacebook2 from '~/assets/svg/facebook-2.svg?inline'
import IconWhatsapp from '~/assets/svg/whatsapp.svg?inline'
import { generateImageURL } from '~/utils/storage'
import IconArrowLeftCircle from '~/assets/svg/arrow-left-circle.svg?inline'
import IconArrowRightCircle from '~/assets/svg/arrow-right-circle.svg?inline'

Swiper.use([Navigation, Pagination, Autoplay])

export default {
  components: {
    IconCloseCircle,
    IconCopy,
    IconFacebook2,
    IconWhatsapp,
    IconArrowLeftCircle,
    IconArrowRightCircle,
  },

  extends: BootstrapModal,

  mixins: [canShare, hasWindow],
  props: {
    bodyClass: {
      type: Array,
      default: () => ['navbar-above-modal', 'navbar-lg-below-modal'],
    },
    gallery: { type: Object, default: () => ({}) },
  },

  data() {
    return { imageURL: null }
  },

  // mounted() {
  // },

  // beforeDestroy() {
  //   this.swiper && this.swiper.destroy()
  // },

  methods: {
    initSwiper() {
      setTimeout(() => {
        this.swiper = new Swiper(this.$refs.swiperContainer, {
          navigation: {
            nextEl: this.$refs.swiperNext,
            prevEl: this.$refs.swiperPrev,
          },
          resistanceRatio: 0,
        })
      }, 150)
    },
    generateImageURL,
    show(imageURL) {
      this.imageURL = imageURL
      this.modal.show()
    },
  },
}
