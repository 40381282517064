import { render, staticRenderFns } from "./GalleryItemImageModal.vue?vue&type=template&id=c0345104&scoped=true&"
import script from "./GalleryItemImageModal.vue?vue&type=script&lang=js&"
export * from "./GalleryItemImageModal.vue?vue&type=script&lang=js&"
import style0 from "./GalleryItemImageModal.vue?vue&type=style&index=0&id=c0345104&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0345104",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkDownload: require('/var/www/charm-app/components/link/LinkDownload.vue').default})
