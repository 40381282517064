import { render, staticRenderFns } from "./GalleryItemImage.vue?vue&type=template&id=7fc5609c&scoped=true&"
import script from "./GalleryItemImage.vue?vue&type=script&lang=js&"
export * from "./GalleryItemImage.vue?vue&type=script&lang=js&"
import style0 from "./GalleryItemImage.vue?vue&type=style&index=0&id=7fc5609c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fc5609c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GalleryItemImageModal: require('/var/www/charm-app/components/gallery/GalleryItemImageModal.vue').default})
