//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import canDownload from '~/mixins/canDownload'

import IconDownload from '~/assets/svg/download.svg?inline'

export default {
  components: { IconDownload },
  mixins: [canDownload],

  props: {
    URL: { type: String, default: null },
    title: { type: String, default: null },
  },
}
