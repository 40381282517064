import slugify from '@sindresorhus/slugify'
import axios from 'axios'
import { saveAs } from 'file-saver'

export default {
  data() {
    return { isDownloading: {} }
  },

  methods: {
    async download(URL, title) {
      try {
        this.$set(this.isDownloading, URL, true)

        const _axios = URL.startsWith(`${this.$axios.defaults.baseURL}/v1`)
          ? // With authentication.
            this.$axios
          : // Without autherntication.
            axios
        const response = await _axios.get(URL, { responseType: 'blob' })
        const filename = title
          ? `${slugify(title)}.${URL.split(/[#?]/)[0].split('.').pop()}`
          : URL.split('/').pop()

        saveAs(response.data, filename)
      } catch (error) {
        console.error(error)
        window.open(URL, '_blank')
      } finally {
        this.isDownloading[URL] = false
      }
    },
  },
}
