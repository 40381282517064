//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BootstrapModal from '../bootstrap/BootstrapModal'
import youTubeIframeAPI from '~/mixins/youTubeIframeAPI'

import IconCloseCircle from '~/assets/svg/close-circle.svg?inline'
import IconCopy from '~/assets/svg/copy.svg?inline'
import IconFacebook2 from '~/assets/svg/facebook-2.svg?inline'
import IconWhatsapp from '~/assets/svg/whatsapp.svg?inline'

export default {
  components: { IconCloseCircle, IconCopy, IconFacebook2, IconWhatsapp },
  extends: BootstrapModal,

  mixins: [youTubeIframeAPI],

  props: {
    bodyClass: {
      type: Array,
      default: () => ['navbar-above-modal', 'navbar-lg-below-modal'],
    },
    gallery: { type: Object, default: () => ({}) },
  },

  data() {
    return { isPlayerInitialized: false }
  },

  mounted() {
    this.handleShow = this.handleShow.bind(this)
    this.handleHide = this.handleHide.bind(this)

    this.$el.addEventListener('show.bs.modal', this.handleShow)
    this.$el.addEventListener('hide.bs.modal', this.handleHide)
    if (this.$route.query.id === this.gallery.id.toString()) {
      this.modal.show()
    }
  },

  beforeDestroy() {
    this.$el.removeEventListener('show.bs.modal', this.handleShow)
    this.$el.removeEventListener('hide.bs.modal', this.handleHide)
  },

  methods: {
    async handleShow() {
      if (this.isPlayerInitialized) {
        this.player.playVideo()
      } else {
        await this.initPlayer()
      }
    },

    handleHide() {
      this.player && this.player.pauseVideo()
    },

    async initPlayer() {
      await this.youTubeIframeAPIReady()

      const player = this.$el.querySelector('.embed-responsive-item')
      this.player = new window.YT.Player(player, {
        height: player.offsetHeight,
        width: player.offsetWidth,
        videoId: this.gallery.youtube_id,
        events: {
          onReady: () => this.player.playVideo(),
        },
      })
      this.isPlayerInitialized = true
    },
  },
}
